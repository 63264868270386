import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import Carousel from "react-bootstrap/Carousel";
import ContactForm from "../components/forms/contactForm";
import ContentQuoteCenter from "../components/contentQuoteCenter.js";
import DefaultHeader from "../components/default-header";
import Footer from "../components/footer";
import GdprPanel from "../components/gdpr";
import LocationInfoSidePanel from "../components/locationInfoPanel";
import LocationsSideMenu from "../components/locationsMenu";
import Navigation from "../components/navigation";
import Seo from "../components/seo";

import "../styles/carousel.scss";
import "../styles/location.scss";
import NewsletterSignup from "../components/newsletterSignUp.js";
import { Celebrating30Years } from "../components/banner.js";

export default function LocationPage({ data }) {
  const post = data.mdx;
  const shortcodes = {
    ContentQuoteCenter,
    Row,
    Col,
    Celebrating30Years
  };

  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          type="xl"
          headline={post.frontmatter.headline}
          heroBackground={post.frontmatter.heroBackground}
          heroMobileBackground={post.frontmatter.heroMobileBackground}
          subHeadline={post.frontmatter.subheadline}
          location={post.frontmatter.location}
        />
        <div id="body" className="location">
          <Container fluid="lg">
            <Row>
              <Col className="d-block d-lg-none mb-3">
                <LocationInfoSidePanel
                  location={post.frontmatter.location}
                ></LocationInfoSidePanel>
              </Col>
              <Col xxl={9} lg={8} className="pe-md-5">
                <Row className="white-bg mb-4">
                  <Col className="p-4">
                    <MDXProvider components={shortcodes}>
                      <MDXRenderer>{post.body}</MDXRenderer>
                    </MDXProvider>
                  </Col>
                </Row>
              </Col>
              <Col xxl={3} lg={4} className="d-none d-lg-block">
                <LocationInfoSidePanel location={post.frontmatter.location} />
                <LocationsSideMenu />
              </Col>
              <Col className="d-block d-lg-none">
                <LocationsSideMenu />
              </Col>
              <Col xs={12}>
              {
                post.frontmatter.location === "LA" ?
                  <Celebrating30Years />
                :
                  null
              }
              <Row className="white-bg mb-4 mb-xl-5">
                  <Col className="p-4">
                    <h1>Explore the Center</h1>
                    <Carousel>
                      {post.frontmatter.images.map((image, i) => (
                        <Carousel.Item key={i++}>
                          <img
                            className="d-block w-100"
                            src={` ${image} `}
                            key={i}
                            alt={`${post.frontmatter.location} Center Photos`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <ContactForm submitCampaignID="7013i000000qH4WAAU" submitType="lead" />
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
}

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        images
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
        location
      }
      body
    }
  }
`;
